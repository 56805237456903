var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WidgetFrame", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" Active Tasks ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.canView("PROJECT", ["TASK"])
              ? _c("div", { staticClass: "center-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_show_data"
                        )
                      ) +
                      " "
                  ),
                ])
              : !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : [
                  _c("ag-grid-vue", {
                    staticClass: "ag-grid ag-theme-balham",
                    attrs: {
                      gridOptions: _vm.gridOptions,
                      columnDefs: _vm.columnDefs,
                      context: _vm.context,
                      defaultColDef: _vm.defaultColDef,
                      pagination: "",
                      paginationPageSize: 1000,
                      rowData: _vm.rowData,
                      overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
                      sideBar: false,
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: "",
                      suppressScrollOnNewData: "",
                      suppressContextMenu: "",
                      suppressMultiSort: "",
                    },
                    on: { "grid-ready": _vm.onGridReady },
                  }),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }